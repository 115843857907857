import React from 'react'
import { Link } from 'gatsby'


const labirintoDesktop = () => {
  return (
    <div className="svg-wrapper desktop-display">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1371.47 757.49">
        <g id="Layer_2" data-name="Layer 2">
          <g id="labirinto">
            <path d="M706.06,218.29c.92-9.5.07-20.09-5.91-29.75-6.84,8.2-11.69,25.78-10.6,44.62,0,0,26,1.93,43.1-1.33C753,228,750,207.35,749.57,204.75c-3.28-20.23-20.88-27.39-24.65-50.75-3.7-22.88,2.9-47.86,2.9-47.86s-8.44-3.42-17-6.59c0,0-5.25,22.09-5.71,41.88-.36,15.28,4,26.72,13.53,44.95,12.57,24.17,8.86,31.91,8.86,31.91" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M846.25,310.19a22.35,22.35,0,0,1-18.12,9.62c-12.69,0-23-11.15-23-24.9s10.28-24.9,23-24.9,23,11.15,23,24.9" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M860.06,337.84c-6.53,7.39-20,12.42-31.45,12.42-27.63,0-50-23.73-50-53s22.4-53,50-53" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M847.56,248.2c13.68,5.93,26.62,18.9,31.08,34" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M643.71,175.59c3.7.06,10.84,7.23,13.95,20.95,2.51,11.07,6.82,23.26,7.28,35.22.4-12.16,4.73-24,7.28-35.22,3.11-13.72,10.26-20.89,14-20.95" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M636.89,94.43c10.62,27.66,11.47,71.67,3.69,82.3,0,0,19.59-7,21.51-13.4A67.61,67.61,0,0,0,664.5,151a.45.45,0,0,1,.34-.38h0a.44.44,0,0,1,.54.39,63.64,63.64,0,0,0,2.42,12.34c1.93,6.43,21.5,13.4,21.5,13.4-7.77-10.63-6.92-54.64,3.7-82.3" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M602.4,218.29s-3.72-7.74,8.85-31.91c9.49-18.23,15-29.74,13.54-44.95-1.7-17.78-2.7-31.4-7.37-39.78a109.64,109.64,0,0,1-15.35,4.49s6.59,25,2.9,47.86c-3.78,23.36-21.37,30.52-24.66,50.75-.42,2.6-3.38,23.21,16.92,27.08,17.14,3.26,43.11,1.33,43.11,1.33h0c.85-16.74-4.26-37-10.61-44.62-6,9.66-6.83,20.25-5.91,29.75" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M482.72,308.83a22.53,22.53,0,0,0,19,11c12.68,0,23-11.15,23-24.9s-10.28-24.9-23-24.9-23,11.15-23,24.9" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M501.28,244.27c-27.64,0-50,23.72-50,53A54.61,54.61,0,0,0,464,332.63" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M802.91,374.89c-26.09-9.11-45.88-33.34-50.53-63.12-13,2.7-59.39-7.45-77.74-42.72a62.4,62.4,0,0,1-5.68-14.7h-8.31a79,79,0,0,1-5.41,14.7c-18.34,35.26-64.84,46.59-77.91,43.9l0,0,.14-.78c-3.63,22.57-16,41.92-33.13,53.86" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M628.21,37.73a37.24,37.24,0,1,1,43.35,36.74" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M658.81,74.87a37.28,37.28,0,0,1-29.23-26.6" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1278.23,331.68s-23.48-17.34-45.66,6.35c-13.5-15.86-57.15-11.43-69.92,6.38-19.76-21.36-58.13-19.12-69.66-5.91-22.34-23.54-45.7-6-45.7-6,38.49,18.6,43,64.66,30.66,60.07-52.71,1.57-79.49,61.36-45.26,100.1,40.15,39.54,115.42-2.06,77.52-51.73-33.71-32.12-73.68,8.19-44.08,26.87" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <line x1="326.74" y1="555.43" x2="354.1" y2="555.43" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M916.08,514.51l1.06-85.1s-9,.68-10.9-11.87c-1.09-7.35,2.82-13.27,14-23.24A65.15,65.15,0,0,1,893.81,387M819.86,583.76c.34-26.76,23-48.67,49.8-48.34l.58-79.19m108.52,0,.58,79.19c26.76-.33,49.46,21.58,49.8,48.34s-21.11,48-47.85,49.06c-11.09.42-20.35-1.48-27.53-4.89-20.64,9.66-29.26,32.65-29.26,32.65s-8.62-23-29.26-32.65c-7.18,3.41-16.44,5.31-27.53,4.89a50.14,50.14,0,0,1-38-19.37m252.11-165.6c11.48,1.49,19.76,15,13.24,28.21-5.88,11.86-31,14.88-44.67,3.43-13.93-11.66-17.23-45.27,2.65-59.95,12.19-9,34.92-12.79,52.33-2.65a59.25,59.25,0,0,1,29.82,52.22c0,30-26.73,54.68-60.09,55.1-43.52.54-71.94-29.07-73.07-68.27m-46.84,58V432.6l66.13-.82M963.75,381.6c-9.87,7.32-25,11.91-38,12.69C937,402.66,942,409.58,941.09,417c-1.6,13-12.79,13-12.79,13l.49,107.1m17.14-269.65c26,8.41,45.16,32.46,44.09,61-1,25.95-12.45,39.31-12.45,39.31,3.75-2.85,8.14-3.86,13.14-3.93,13.29-.16,24.21,11.19,24.39,25.36s-10.45,25.8-23.75,26a24.83,24.83,0,0,1-19.81-8.77c-2.39-2.87-3.66-7.54-3.71-11.57m-96.4-27.05S860,354.51,859,328.56s15.22-48.33,37.89-58.43m67.37,266.56h0l-35.45.5-.49,31s4.7,6.25,5.33,21.27c1.56-20.2,13.7-30.12,30.14-30.33s29.92,11.38,30.1,25.88-11.82,24.56-26.23,24.73C977,608.18,979.73,600.9,979,595c-1.08-9.12-9.74-15.08-21.52-14.93s-21.15,4.84-20.63,17c.79,18.47,19.32,29.8,38.16,29.56,23.36-.29,42-20.7,41.73-45.58a46.74,46.74,0,0,0-9.87-28.35M938.17,384.54C953,376.27,964,355.81,963.74,332c-.38-30.9-17-55.74-38.39-55.47h-.85c-21.38-.27-38.86,24.57-39.25,55.47M920.7,568.24l-.49-31-26.4-.37V432.6l-66.14-.82s.65,62.75-52.57,60.56c-11.15-.46-28-8.49-31.62-20.48-6.87-22.41,8.21-39.83,20.37-41.77M842,552.82a46.74,46.74,0,0,0-9.77,28.23c-.31,24.88,18.37,45.29,41.73,45.58,18.84.24,37.37-11.09,38.16-29.56.52-12.14-8.85-16.84-20.63-17S871,585.9,870,595c-.69,5.88,2,13.16,11.4,14.77-14.41-.17-26.41-10.24-26.23-24.73s13.66-26.08,30.1-25.88,28.57,10.13,30.14,30.33" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M394.91,378.12V364.26s-70.72-1.52-70.72,82.61c0,81.29,69.85,81.26,69.85,81.26m-1.31-.66s-19.12-13.61-19.53-47.27H359.61v21" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M414.38,98.92h-110v49l24.83,18.59,23.52-8.49L341,184.19M353,322l61.75-.55-.46-54.11L391.9,245.6l-23.52,10.45" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1297.32,690.46l.57.3" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M380.71,656.05c6.69,8.06,1.74,18.74-3.81,20.94-7.17,2.85,21.7,28,21.58,27.93h0s28.67-25.13,21.58-28c-5.55-2.21-10.49-12.88-3.81-20.94s17.54-6.54,21.85-2.7c7.83,7,6.07,15.39,2.9,23-1.89,4.54-14.89,19.49-25.38,45.69-1.81,4.5-4.09,11-1.27,17.13a15.57,15.57,0,0,0,12,8.89c7.19.83,12.81-2.67,15.92-9.09a13.06,13.06,0,0,0-2.59-14.39c-4.72-5-12.09-2.63-14.53-1.6.16-.09.35-.18.57-.31,12.69-7,30.46-34.27,30.46-34.27s17.77,27.29,30.46,34.27l.58.31c-2.44-1-9.81-3.38-14.54,1.6a13.07,13.07,0,0,0-2.59,14.39c3.12,6.42,8.74,9.92,15.92,9.09a15.6,15.6,0,0,0,12.06-8.89c2.82-6.11.53-12.63-1.27-17.13-10.5-26.2-23.49-41.15-25.39-45.69-3.17-7.62-4.92-16,2.91-23,4.3-3.84,13.75-3.79,21.84,2.7,8.76,7,32.81,24.84,66.08-10.45,10.12,13.3,45,19.31,71.74,11.28,16-4.8,25.89-12.68,32.77-20.47h0c6.87,7.79,16.78,15.67,32.77,20.47,26.77,8,61.62,2,71.74-11.28,33.27,35.29,68.27,9.25,68.27,9.25" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polygon points="398.18 657.94 386.6 643.02 398.66 628.55 409.76 642.51 398.18 657.94" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="513.64 657.94 502.06 643.02 514.12 628.55" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M867.76,380.65A15.38,15.38,0,0,1,883,396.22" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M883,396.22c-.05,4-1.32,8.7-3.71,11.57" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M879.24,407.79a24.84,24.84,0,0,1-19.82,8.78" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M859.42,416.57c-13.29-.17-23.92-11.79-23.75-26" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M835.67,390.6c.18-14.17,11.1-25.52,24.39-25.35" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M860.06,365.25c5,.06,9.39,1.07,13.14,3.93" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M685.13,69.64,748,93.42s-6.61,25.15-1.73,49.92c1.74,8.83,2.42,15.45,12.48,32.89,9.3,16.14,15.63,26.29,13.35,45-3.2,26.1-10.94,30.46-29.31,33.84-20.14,3.71-49.22.36-49.22.36,1.31,5.49,2.15,5.94,4.75,9.79,11.58,17.17,42.57,30.4,53.25,26.64,3-41.43,35.15-74,74.29-74,31.94,0,59.2,21.7,69.8,52.2M394.08,98.92H527V221.71c27.58,9.66,48.12,36.21,51.14,68.36.26,2.7.26-.08.26,2.7v-.95c10.67,3.76,41.56-9.47,53.14-26.64a42.61,42.61,0,0,0,4.75-9.79h0s-29.08,3.35-49.23-.36c-18.37-3.38-26.1-7.74-29.3-33.84-2.29-18.67,4.05-28.82,13.35-45,10-17.44,10.74-24.06,12.48-32.89,4.87-24.77-1.73-49.92-1.73-49.92l62.25-23.54s19.69,34.79,20.77,69.48l.12-1q.16-3.93.62-7.85c1.79-15.53,6.69-30.48,11.21-41.65" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M371.76,562.38l-.57.3c-12.69,7-30.46,34.27-30.46,34.27S323,569.67,310.27,562.68l-.58-.3c2.44,1,9.81,3.38,14.54-1.6a13.72,13.72,0,0,0,3.08-5.19" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M310,534.2s-20.65-14.68-21.09-51H274.25v22.69" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="252.68 563.56 252.9 534.66 310.02 534.2" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M210.21,505.11l10.88.05-.22-21.94h20.82v22.42H252.9V428.23h13.88l.27-22.69h7.2v22.69h14.68c.44-36.3,21.09-51,21.09-51" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M240,592.65l-.27-22H220.56v22H194.81V579.83h14.85l.27-45.63h-57.7s21.48-13.75,21.9-51H188v22.42l7.47.14,0-22.56h14.72V406.34h10.88l.26,21.9h20.34V405.8H252.9" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M188,405.8v22.43H174.13c-.42-37.24-21.9-51-21.9-51h57.7l-.43-28.82,43.18-.53v-7.08H220.56l.27-22h42.89v12.81H246.91" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <line x1="252.9" y1="376.78" x2="310.02" y2="377.24" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="194.81 359.36 194.81 318.8 210.02 318.8 210.02 331.61" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M84.75,378.61s65.3-.33,65.3,74.53c0,75.67-64.33,77-64.33,77" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M276,571v-15.9h21.75a32.19,32.19,0,0,0,2.39,8.18c10.5,26.2,23.49,41.15,25.39,45.69,3.17,7.62,4.92,16-2.9,23-4.31,3.84-15.24,5.29-21.85-2.69s-1.74-18.73,3.8-20.94c7.09-2.83-21.57-28-21.57-28h0a158.38,158.38,0,0,0-13.29,12.24H259.11V579.83" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="76.54 557.78 185.34 555.99 185.34 594.21 185.34 614.36 274.03 614.36" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M250.47,660.1l-80.06.59c.34,27-21.81,50-48.86,50.33S73,689.69,72,662.66c-.42-11.22,1.5-20.58,4.94-27.84-9.76-20.86-33-29.57-33-29.57s23.24-8.72,33-29.58" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="275.97 318.04 275.97 359.36 353.02 359.36 353.02 321.98" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M313.87,751.48c-3-20.25-16.05-38.26-36.65-47.43-9.81-4.37-44.05-12.56-67.3,14.56-8.58,10-11.33,22.93-11.36,30.56" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M181.75,757.48c-.55-44,29.39-72.72,69-73.86" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="192.16 636.27 274.35 636.27 275.19 703.13" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M110.17,660.38c9.21-1.09,15.24-9.85,15.09-21.76s-4.89-21.37-17.16-20.85c-18.68.8-30.13,19.53-29.89,38.58.3,23.61,20.93,42.5,46.08,42.18,10.92-.14,22.11-6.7,25.76-14.91" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M136.62,622.16c6.43,5.47,9.66,13.44,9.77,22.79.21,16.62-11.5,30.24-26.15,30.42s-24.83-12-25-26.52" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M169.13,645.42c0-.42,0,.42,0,0l-.51-35.84-31.38-.49" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M356.62,188.11l28.1-39.2L367.08,128,324.87,146.9l-.33-27.69,69.83-.43L394,195.3H304.36v83.05c-2.29-5.09-9.55-7.66-16.06-5-6.14,2.49-8.56,9-7.79,15.7a13.68,13.68,0,0,0,2.1,5.79H185.34V359.4l-100.7.18" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M463.32,515.8a71.49,71.49,0,0,0,15.21,22.27c-3.52,1.13-6.42,3.8-8.42,7.92-2.13,4.39-1.71,11.26,2.62,14.83,6.51,5.37,12.1,2.63,14.54,1.6l-.58.31C474,569.71,456.23,597,456.23,597s-17.77-27.29-30.46-34.27c-.22-.13-.41-.22-.57-.31,2.44,1,9.81,3.38,14.53-1.6a13.06,13.06,0,0,0,2.59-14.39c-3.11-6.42-8.73-9.92-15.92-9.09a15.57,15.57,0,0,0-12,8.89c-2.82,6.11-.54,12.63,1.27,17.13,10.49,26.2,23.49,41.15,25.38,45.69,3.17,7.62,4.93,16-2.9,23-4.31,3.84-15.23,5.29-21.85-2.7s-1.74-18.73,3.81-20.94c7.09-2.83-21.58-28-21.58-28h0c.12-.1-28.75,25.08-21.58,27.93,5.55,2.2,10.5,12.88,3.81,20.94s-17.54,6.53-21.85,2.69c-7.83-7-6.07-15.38-2.9-23,1.89-4.54,14.89-19.49,25.39-45.69,1.8-4.51,4.09-11,1.26-17.14a15.56,15.56,0,0,0-12.05-8.88c-7.19-.83-12.81,2.66-15.92,9.09a12.42,12.42,0,0,0-.54,9" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M520.8,636.94A87.7,87.7,0,0,0,535.23,621c12-17.08,15.34-40.3,13.38-56.12h-.35a166.06,166.06,0,0,1-20.54-1.76,86.77,86.77,0,0,1-28.21-9.6,30.93,30.93,0,0,1-2.37,9.86c-10.5,26.2-23.78,41.09-25.68,45.63-3.17,7.62-4.92,16,2.91,23,4.3,3.84,15.23,5.29,21.84-2.7s1.74-18.74-3.8-20.94C485.3,605.61,514,580.47,514,580.47s6.67,5.83,12.78,12.19" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M565.87,442.83c-4.83-9.67-18.26-15.35-32.75-15.69l.23-40.06s59.57,1.24,59.57,71.41c0,69.42-60.46,69.1-60.46,69.1" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M534.34,508.34A34.29,34.29,0,0,1,522.55,503c-9.39-6.88-13.59-14.23-14.7-21.13H531c-11.82-8.08-8.18-29.27-8.18-29.27" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M521.85,393.91c.07.22-10.48-27.46-41.29-48.31a43.2,43.2,0,0,0,20.72,4.66c27.63,0,50-23.73,50-53,0-21.47-12-40-29.39-48.29" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M375.32,409.68a82.86,82.86,0,0,0-2.1,19.52H359.63V398.71a72.83,72.83,0,0,1,37.33-4.8c-.08.22,10.67-29.62,45.14-50.76-8.33-12.18-12.59-28.76-12.59-44.86,0-44.46,33.37-80.49,74.53-80.49l-34.66-.43-22.27-26.19,11-24.79-27.54,14.46-6.89-20,45.45-29.61,25.47,20-22.93,42.68,32.19-.16L504,120l-89.55.72v95.2l-32.24.06,0,13.93-18.94-6.53-28.09,43.11L354,290.66l40.5-21.76-.15,30.54-70,.68.68-84.2" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M459.3,448.7s0-37.87,37-47.38c-.82-8.41-3.14-17.1-7.51-24.23-12.51-2.55-23.51-9.12-33-17.51-22.7,4.68-29.92,19.3-32.71,36.87C408.65,405.34,395,422.12,395,456.26c0,71.65,60.13,70.34,60.13,70.34l-.25-44.72h27.73c.16,17.12,6.43,34.63,21,45.32,18.19,13.33,52.07,18.86,77.93,3.68,38.35-22.5,49.43-66.6,39.14-98.81C609.55,397.33,570.22,374,570.22,374c35.76-4.5,63.11-24.44,97.39-63.2,31.42,38.29,61,58.3,96.94,62.82,0,0-39.48,23.46-50.62,58.32-10.33,32.33.79,76.58,39.28,99.16,13.32,7.82,28.75,10.17,43.09,8.79l0,.37c-19.89,22.11-14.3,55,3.35,81.33" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M643,591.5c-12.32-3.68-33.25,5.69-31.22,4.3,22.57-15.33,31-20.54,55-56.7,24,36.16,32.57,41.37,55.13,56.7,2,1.39-18.9-8-31.22-4.3-8.81,2.63-12.62,15.19-6,24.88,5.63,8.28,17.08,12.37,29.6,13.22,20.23,1.39,31-11.41,35.1-20,11.07-23.46,6.1-38.91,4-48.69a99.49,99.49,0,0,1-38.52-20.69,94.32,94.32,0,0,1-24-32.85c-7.16-17-8.65-37.21-4.25-64.67,5.79-36.14,33.18-58.32,33.18-58.32a115.34,115.34,0,0,1-15.68-3.1c-16-4.33-37.14-17.47-37.14-17.47a118.89,118.89,0,0,1-37.14,17.47,114.9,114.9,0,0,1-15.67,3.1s27.38,22.18,33.17,58.32c4.41,27.46,2.92,47.7-4.24,64.67a94.53,94.53,0,0,1-24,32.85,99.46,99.46,0,0,1-38.51,20.69c-2.16,9.78-7.12,25.23,3.94,48.69,4.05,8.59,14.88,21.39,35.1,20,12.52-.85,24-4.94,29.6-13.22" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1187.23,514.87l142.89-.36.31,24.18c-40.73,1.17-72.27,29.88-71.7,75.09.57-45.21-30.77-73.92-71.5-75.09" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1297.77,476.74l-.31-23-27.65-.35V430.26h84.86l0-24.49H1187.53s-.83-25.53,12-34.34c1.36-.93-12.62,5.39-20.87,3-5.93-1.75-8.5-10.12-4.1-16.64,3.74-5.55,11.4-8.31,19.77-8.92,13.53-1,20.81,7.57,23.54,13.31" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1097,520.29c24.18,2.16,45.25,16.86,66.36,42.42,0,0,43,.38,58.16,32.29,6.23,13.09,7.76,45.46-17.78,55.85-18.51,7.52-32.14-1.16-38.37-11.74s-2-21.55,4.38-24.08c8.25-3.28-25-32.24-24.82-32.12h0s-33,28.9-24.82,32.15" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1354.48,636.38c-6.17,22.75-50.46,23.39-56.57-1.18C1304,615.78,1348.31,615.13,1354.48,636.38Z" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1247.46,494l-84.11,0-.13,32.43a79.7,79.7,0,0,0-35-13.27s14.6-12.16,20.43-31.87h17.81V470.33l103.39.55.39,23.15h78.11v69.13s-38.24-.88-51.91,31.17a44.49,44.49,0,0,1,18.7-3.41c24.6,1.41,49.37,13.53,54.79,36.12,6.4,26.6-16.21,53.15-54.13,53.62a49.72,49.72,0,0,1-20.31-4.08c13.32,32.73,59.42,32.27,59.42,32.27" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M1152.5,447.19c-.26-5.62-3.65-14.45-5-17.6-1.45-3.42-6.4-11.93-16.41-20.73a66.68,66.68,0,0,0-25.82-13.76c-1.46-6.54-4.82-16.86,2.53-32.59,2.69-5.75,9.9-14.35,23.44-13.46,8.38.54,16,3.25,19.84,8.78,4.44,6.49,1.92,14.88-4,16.66-8.23,2.49-22.26-3.73-20.9-2.81,15.18,10.27,22.84,16.75,36.52,37.23l.27,21.35h84.73l.13,23.14-81.43-.25V441.59" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M86.21,500.28A95.46,95.46,0,0,0,66.4,537.17C57,539.24,42.23,544,19.76,533.4,11.53,529.52-.73,519.15.6,499.78c.82-12,4.74-23,12.67-28.35" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M13.27,437.34C5.34,432,1.42,421,.6,409c-1.33-19.37,10.93-29.74,19.16-33.62C42.23,364.78,57,369.53,66.4,371.6a95.46,95.46,0,0,0,19.81,36.89" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M87.28,454.47c-34.63-23-39.62-31.2-54.3-52.81-1.33-2,7.64,18.1,4.12,29.9" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M37.1,477.21c3.52,11.8-5.45,31.85-4.12,29.9,14.68-21.61,19.67-29.65,54.3-52.64" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
          </g>
          <g id="entradas_do_labirinto" data-name="entradas do labirinto">
            <Link to='/berço-venus/'>
            <circle id="berço-de-venus" className="labirinto-link-desktop" cx="472.23" cy="155.01" r="11.57" style={{fill: '#fae5ea'}} />
            </Link>
            <Link to='/guia-interior/'>  
            <ellipse id="guia-interior" className="labirinto-link-desktop" cx="665.64" cy="15.36" rx="18.09" ry="5.5" style={{fill: '#fae5ea'}} />
            </Link>
            <Link to='/mente-certa/'>
            <circle id="mente-certa" className="labirinto-link-desktop" cx="983.66" cy="393.92" r="15.98" style={{fill: '#fae5ea'}} />
            </Link>
            <Link to='/mundo-sem-origem/'>
            <circle id="mundo-sem-origem" className="labirinto-link-desktop" cx="768.77" cy="454.39" r="24.48" style={{fill: '#fae5ea'}} />
            </Link>
            <Link to='/sintese-radiante/'>
            <ellipse id="sintese-radiante" className="labirinto-link-desktop" cx="501.28" cy="294.91" rx="17.48" ry="19.75" style={{fill: '#fae5ea'}} />
            </Link>
            <Link to='/toda-materia/'>
            <rect id="toda-materia" className="labirinto-link-desktop" x="418.27" y="437.19" width="22.24" height="46.78" style={{fill: '#fae5ea'}} />
            </Link>
            <Link to='/verbo-sideral/'>
            <rect id="verbo-sideral" className="labirinto-link-desktop" x="215.81" y="433.63" width={31} height="44.19" style={{fill: '#fae5ea'}} />
            </Link>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default labirintoDesktop