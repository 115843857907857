import React from 'react'
import { Link } from 'gatsby'

const labirintomobile = () => {
  return (
    <div className="svg-wrapper mobile-display">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1063.8 1154.76">
        <g id="Layer_2" data-name="Layer 2">
          <g id="labirinto">
            <path d="M565.71,107.86a195.37,195.37,0,0,0-8.7,34.71c-3.63,24.58-2.46,55.64,4,60.63" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M580.3,251c1.06-10.92.08-23.1-6.8-34.22-7.86,9.44-13.44,29.65-12.19,51.32,0,0,29.86,2.23,49.57-1.53,23.36-4.45,20-28.15,19.46-31.13-3.77-23.28-24-31.51-28.35-58.39-4.25-26.31,3.33-55.05,3.33-55.05s-9.7-3.92-19.56-7.57c0,0-6,25.41-6.57,48.17-.41,17.57,4.66,30.73,15.57,51.71C609.22,242.11,605,251,605,251" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M741.55,356.71a25.69,25.69,0,0,1-20.85,11.06c-14.59,0-26.41-12.82-26.41-28.64s11.82-28.63,26.41-28.63,26.41,12.82,26.41,28.63" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M743.06,285.41c15.73,6.82,30.61,21.73,35.75,39.06" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M561,203.2c-8.94-12.22-8-62.85,4.26-94.66" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M500.73,108.54C513,140.35,513.93,191,505,203.2" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M500.3,107.86A195.87,195.87,0,0,1,509,142.57c3.63,24.58,2.47,55.64-4,60.63" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M461.06,251s-4.27-8.89,10.19-36.69c10.91-21,17.24-34.21,15.57-51.71-1.95-20.45-3.1-36.12-8.48-45.76A125.22,125.22,0,0,1,460.69,122s7.58,28.74,3.33,55.05c-4.34,26.88-24.58,35.11-28.36,58.39-.48,3-3.89,26.68,19.47,31.13,19.71,3.76,49.57,1.53,49.57,1.53h0c1-19.24-4.9-42.57-12.19-51.32-6.88,11.12-7.86,23.3-6.8,34.22" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M691.69,431.13c-30-10.48-52.77-38.35-58.11-72.6-15,3.1-68.32-8.57-89.42-49.14a72.18,72.18,0,0,1-6.54-16.91h-9.56a91.52,91.52,0,0,1-6.21,16.91C500.75,350,447.26,363,432.23,359.88l0,0,.15-.9c-5.82,36.24-31.14,65.25-63.87,74" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M490.76,43.33a42.83,42.83,0,1,1,85.65,0c0,21.25-14.72,38.89-35,42.25" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M526,86.94c-15.85-2.66-29-14.76-33.62-30.6" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M667.22,894.78l-35.78-.26-.58-70.74s-18.18,25.57-63.13,26.11v18.18h28.1V877l-28.1.33" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M567.73,964.84l27.94.74-.17,9.26H567.73v17.22c46.11.51,63.13,27.12,63.13,27.12V947.73l56.52.33v18.39h15.86V934.56H676V910.83l27.26-.34" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M499.63,965.91,472,965.58l-.17,9.26h27.76v17.22c-46.1.51-63.13,27.12-63.13,27.12V947.73l-35.7.53-.66-53.48h-8.75v39.78l-27.27-.34V881.11H380v20.83" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M435.93,894.52l.57-70.74s18.19,25.57,63.13,26.11v18.18H471.54V877l28.09.33" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="414.36 966.45 364.12 966.45 364.12 947.61 379.99 947.61" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M438.2,1102.74s-.42-80.86,92.29-80.86c93.7,0,95.37,79.67,95.37,79.67" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M821.87,591.72l1.22-97.89s-9.36,4.13-10.63-14.22c-.59-8.52,1.33-14.69,14.17-26.15A75,75,0,0,1,796.25,445m69,277.16c-23.73,11.11-33.65,37.55-33.65,37.55s-9.92-26.44-33.65-37.55c-8.26,3.91-18.91,6.11-31.67,5.63-30.76-1.17-55.42-25.65-55-56.43s26.49-56,57.27-55.61l.67-91.08M866.85,591V497.51l76.07-.95M876.7,438.84a74.92,74.92,0,0,1-40.23,14.62c12.84,11.46,14.75,17.63,14.17,26.15C849.37,498,840,493.83,840,493.83l1.22,97.89m53,92.6c-1.23-10.48-11.2-17.34-24.75-17.17s-24.32,5.56-23.73,19.53c.91,21.25,22.22,34.28,43.9,34,26.86-.33,48.35-23.81,48-52.43a53.76,53.76,0,0,0-11.35-32.61m-49.08-18.4c-.48,0,.48,0,0,0l-40.78.57-.56,35.71s5.4,7.19,6.13,24.47c1.8-23.24,15.76-34.65,34.67-34.89s34.41,13.09,34.62,29.76-13.6,28.26-30.17,28.45M815.83,442.23c-17.08-9.51-29.76-33-29.41-60.42.44-35.55,19.76-63.81,45.14-63.81,27.72,0,44.69,28.26,45.14,63.81m-140.05,254a53.69,53.69,0,0,0-11.24,32.47c-.36,28.62,21.13,52.1,48,52.43,21.68.28,43-12.75,43.9-34,.59-14-10.18-19.36-23.73-19.53s-23.52,6.69-24.75,17.17c-.8,6.76,2.32,15.14,13.11,17-16.57-.19-30.38-11.78-30.17-28.45s15.71-30,34.62-29.76,32.87,11.65,34.67,34.89c.73-17.28,6.13-24.47,6.13-24.47l-.56-35.71-30.37-.58V497.51l-76.07-.95S729.38,556,666.83,558C628,559.3,611.67,527.17,612,503.29" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M382.32,714.84c13.92-19.72,26.58-66,9.78-93.55v0c14.76.47,30.15-2.56,43.66-10.49,44.26-26,57.06-76.88,45.17-114.06-12.81-40.1-58.22-67.08-58.22-67.08,41.28-5.2,76.29-28.92,112.43-73h0c36.14,44,71.15,67.76,112.43,73,0,0-45.4,27-58.22,67.08-11.88,37.18.91,88.09,45.18,114.06,15.32,9,33.07,11.69,49.57,10.11l0,.42c-22.88,25.43-16.45,63.32,3.85,93.55m-132.38-6c6.47,9.53,19.64,14.23,34,15.21,23.27,1.6,35.72-13.13,40.38-23,12.72-27,7-44.75,4.53-56-13.49-3.86-29.87-11-44.3-23.8a108.65,108.65,0,0,1-27.64-37.78c-8.24-19.52-10-42.8-4.89-74.39,6.66-41.56,38.16-67.08,38.16-67.08a132.16,132.16,0,0,1-18-3.56c-18.36-5-42.72-20.1-42.72-20.1a137,137,0,0,1-42.71,20.1,132,132,0,0,1-18,3.56s31.5,25.52,38.17,67.08c5.06,31.59,3.35,54.87-4.89,74.39A108.58,108.58,0,0,1,480,621.29c-14.43,12.78-30.8,19.94-44.3,23.8-2.47,11.25-8.18,29,4.54,56,4.66,9.88,17.11,24.61,40.37,23,14.4-1,27.58-5.68,34-15.21m-6.95-28.62c-14.17-4.23-38.25,6.54-35.9,5C497.78,667.59,507.43,661.6,535,620c27.62,41.59,37.47,47.58,63.42,65.21,2.34,1.59-21.74-9.18-35.9-5" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M175.52,391v89.55h15.2c14.29-50.25,60.4-52.37,60.4-52.37m39.67,16.95s-68.31,0-68.31,79.58c0,82.41,69.16,80.91,69.16,80.91l-.28-51.44H380.9m-.28,52.58s69.54.36,69.54-79.49c0-80.7-68.51-82.14-68.51-82.14l-.27,46.34-90.32.56" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M193.49,369.64h51.76l-.52-62.23-25.78-25-27,12M210.68,171.2l-20.28-24L141.85,168.9,141.47,137l80.32-.49-.4,88H118.26V369.64h40.12" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M374.07,681.61c-7-7.31-14.7-14-14.7-14s-33,28.91-24.82,32.15c6.38,2.52,12.07,14.81,4.38,24.09s-20.18,7.51-25.13,3.09c-9-8-7.32-17.84-3.34-26.45,3.23-7,17.13-22.43,29.2-52.56,2.08-5.18,4.7-12.67,1.46-19.71A17.92,17.92,0,0,0,327.25,618c-8.26-1-14.73,3.07-18.31,10.46a15,15,0,0,0,3,16.55c5.44,5.73,13.91,3,16.72,1.84l-.66.35c-14.6,8-35,39.42-35,39.42s-20.44-31.39-35-39.42l-.65-.35c2.8,1.18,11.28,3.89,16.71-1.84,4.45-4.69,5.43-11.5,3-16.55-3.58-7.39-10.05-11.41-18.31-10.46a17.94,17.94,0,0,0-13.87,10.22c-3.24,7-.61,14.53,1.46,19.71,12.08,30.13,27,47.33,29.2,52.56,3.65,8.76,5.67,18.42-3.33,26.45-5,4.42-17.52,6.09-25.14-3.09s-2-21.55,4.38-24.09c8.16-3.26-24.81-32.17-24.81-32.16h0c.13-.12-33.07,28.85-24.82,32.12,6.38,2.53,12.07,14.81,4.38,24.09" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M685.4,866H656.8v-122c37.83,38.39,77.11,9.18,77.11,9.18" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polyline points="659.87 1101.35 657.85 978.18 705.18 978.18 730.13 978.18 730.13 865.95 703.24 866 703.24 886.83 687.38 886.83" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M339.66,830.39c-12.07-30.13-27-47.33-29.2-52.56-3.65-8.76-5.66-18.43,3.34-26.46,4.95-4.41,15.82-4.36,25.13,3.1,10,8,37.46,28.37,75.43-11.42V866H363.18" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polygon points="226.17 756.69 212.85 739.54 226.72 722.89 239.49 738.95 226.17 756.69" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <polygon points="364.64 750.08 351.32 732.93 365.19 716.27 377.96 732.33 364.64 750.08" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M766.29,437.75a17.68,17.68,0,0,1,17.46,17.91" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M783.75,455.66c0,4.64-1.51,10-4.26,13.31" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M779.49,469c-5.59,6.71-13.78,10.21-22.8,10.1" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M756.69,479.07c-15.29-.2-27.51-13.57-27.31-29.87" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M347.91,435.61c-47.35,0-85.73-41.45-85.73-92.58s38.38-92.58,85.73-92.58L308,250M539.7,122.11a166.1,166.1,0,0,0-5.89,28c-.35,3-.86,10.12-.86,10.12-1.24-39.91-23.89-79.92-23.89-79.92l-71.59,27.08s7.59,28.92,2,57.42c-2,10.15-2.79,17.77-14.35,37.83-10.7,18.56-18,30.23-15.36,51.71,3.68,30,12.58,35,33.71,38.92,23.17,4.27,56.62.42,56.62.42h0a48.89,48.89,0,0,1-5.47,11.26c-13.31,19.75-48.84,35-61.12,30.64v1.09c0-3.19,0,0-.29-3.11-3.48-37-27.1-67.51-58.82-78.62V199.83M756.82,375.11c.08-28.77,17.67-53.21,42.95-64.48-12.19-35.08-44.92-60.18-81.67-60.18-45,0-81.93,37.48-85.45,85.13-12.27,4.33-47.93-10.89-61.24-30.64-3-4.43-4-4.95-5.47-11.26,0,0,33.45,3.85,56.62-.42,21.13-3.89,30-8.89,33.7-38.92,2.64-21.48-4.65-33.15-15.35-51.71-11.56-20.06-12.35-27.68-14.35-37.83-5.61-28.5,2-57.42,2-57.42L557,80.3a210.21,210.21,0,0,0-11.25,24.4M729.38,449.2c.2-16.3,12.76-29.36,28-29.17,5,.07,9.36.94,13.25,3.26,0,0-11.62-12.68-13.25-34.78-7.51,8.5-23.06,14.29-36.17,14.29-31.79,0-57.55-27.29-57.55-61s25.76-61,57.55-61" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M903.44,353.55a68.31,68.31,0,0,1,3.47,24.29c-1.11,29.84-14.31,45.22-14.31,45.22,4.31-3.28,9.35-4.52,15.11-4.52,18,0,27.84,12.86,28,29.16s-10.08,30.45-27.32,30.45c-11.43,0-26.41-3.51-27.06-24,0-10.15,8.33-18.8,18.48-18.8a18.38,18.38,0,0,1,18.38,18.38c0,8.23-4.25,14.38-11.71,16.72,13.21-.16,21.16-8.24,21.16-22.79,0-13.57-9.63-21.32-22.84-21.15" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M872.56,742.59c4.44,1.81,20.09,8.83,33.53,27.75,10.18,14.33,13,33.84,11.41,47.15h-.3A139.87,139.87,0,0,0,899.94,819c-25.09,3.75-47.38,19.87-58.75,45.38-5.41,12.15-15.55,54.54,18,83.34,12.38,10.62,28.39,14,37.84,14.07,26.7.11,36.18-1.94,53-16.8,12.87-11.38,17.88-34.66,10.17-53-12.44-29.56-49-33.79-64.46-22.41m78.05,99.19s22.88-18.67,27.63-48.95c3.6-23,2.3-40-3.75-54.17a79.22,79.22,0,0,0-20.22-27.45A83.63,83.63,0,0,0,945.16,821c-1.84-8.19-6-21.11,3.16-40.79,3.37-7.21,12.4-18,29.34-16.86,10.49.68,20.1,4.07,24.84,11,5.56,8.12,2.41,18.62-5,20.85-10.31,3.12-27.87-4.67-26.16-3.51,19,12.85,29.07,21.69,46.2,47.33,20-30.35,26.77-34.74,45.63-47.65,1.7-1.16-15.81,6.74-26.14,3.7-7.41-2.19-10.63-12.66-5.13-20.82,4.69-7,14.28-10.41,24.76-11.17" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M786.77,897.53l.73,99.13c33.5-.42,61.91,27,62.33,60.52s-26.41,60.15-59.89,61.41c-13.89.53-25.48-1.86-34.47-6.12-25.83,12.09-36.63,40.87-36.63,40.87s-10.79-28.78-36.62-40.87M932.36,922.34c-7.36,14.85-40.62,17.38-58.73,0-16.43-15.73-18.76-52.41,6.13-70.79,15.25-11.26,43.72-16,65.51-3.31a74.17,74.17,0,0,1,37.33,65.36c0,37.61-33.47,68.46-75.23,69-54.47.68-90.06-36.39-91.47-85.46m-58.64,72.57V868l82.79-1m-52.93,204.35c-1.35-11.41-12.19-18.87-26.94-18.69s-26.47,6.06-25.82,21.26c1,23.13,24.18,37.31,47.77,37,29.24-.37,52.62-25.91,52.23-57.06A58.49,58.49,0,0,0,822,1018.29m-53.41-20h0l-44.39.63-.61,38.86s5.88,7.83,6.67,26.64c2-25.3,17.16-37.72,37.74-38s37.45,14.24,37.68,32.38-14.81,30.75-32.85,31" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M73.69,916.89l.32,23,27.65.34v23.14H16.8l-.05,24.49H183.93M64.56,593.16l37.46-.22-.09,23.14H39.82l-.48-41.14,63,.51V552.31H73.48V498.78m111.42,4.87-.3,24.17-82.28-.6m72.55,48.86-50.23-.62,0-23.15,57.14,0h15.63c.22,17.89,5,30.86,10.15,39.64l-82.95,1,0,23.15h42.06M124,899.61h84.47l.86-68.71s-44.24-.36-59.41-32.27c-6.23-13.09-7.76-45.46,17.78-55.84,18.51-7.52,32.14,1.15,38.37,11.73s2,21.56-4.38,24.09c-8.25,3.27,25,32.24,24.82,32.12h0s33-28.91,24.81-32.16C245,776,239.27,763.75,247,754.47s20.18-7.51,25.14-3.1c9,8,7,17.7,3.33,26.46-2.17,5.23-17.12,22.43-29.2,52.56-2.07,5.18-4.7,12.67-1.46,19.71a18,18,0,0,0,13.87,10.22c8.26.95,14.73-3.07,18.31-10.46,2.45-5.05,1.47-11.86-3-16.55-5.43-5.73-13.91-3-16.71-1.84l.65-.35c14.6-8,35-39.42,35-39.42s20.44,31.39,35,39.42l.66.35c-2.81-1.18-11.28-3.89-16.72,1.84a15,15,0,0,0-3,16.55c3.58,7.39,10.05,11.41,18.31,10.46a16.89,16.89,0,0,0,7.17-2.59V978.18h80l.21,124.69M17,757.26c6.17-22.75,50.46-23.39,56.56,1.18C67.45,777.86,23.16,778.5,17,757.26Z" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M191.11,714.07s-45.46-.46-59.13,31.6" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M194.66,644.29a20,20,0,0,1-10,1.54l.07,6a142.11,142.11,0,0,0-12.91,14.31c-21.53,4.09-38.95,16.39-48.88,35.28,4.11,5.17,5.54,13.56-.25,22.35-6.09,9.26-21.72,9.8-29.28,1.54s-3.84-22,5.44-24.62c8.93-2.49-12.95-20.63-12.95-20.62-12-9.5-27.15-14.85-44.2-15.34L42,640.57l60.31.44" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M177.21,698.61c2.18-5.23,17.13-22.43,29.2-52.56,2.08-5.18,4.7-12.67,1.46-19.71A18,18,0,0,0,194,616.12c-8.26-1-14.72,3-18.3,10.43a14.81,14.81,0,0,0,1.06,14.08l-52.48.38" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M208.79,987.86l-.31-24.49H123.75l-.13-23.14,81.43.26V952H224V912.36h-18.9V923.3l-103.39-.55-.39-23.14H23.16V830.48s38.24.87,51.91-31.18a44.65,44.65,0,0,1-18.7,3.42C31.77,801.3,7,789.18,1.58,766.6-4.82,740,17.79,713.45,55.71,713A49.64,49.64,0,0,1,76,717.05C62.7,684.32,16.6,684.79,16.6,684.79" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M383.13,130.27c12.18-.15,22.16,8.43,22.29,19.16s-8.75,18.2-19.43,18.32c7-1.19,9-6.58,8.45-10.94-.8-6.75-7.22-11.17-15.94-11.06s-15.66,3.59-15.28,12.58c.58,13.68,14.31,22.07,28.27,21.9,17.3-.22,31.13-15.33,30.9-33.77s-14.6-33.05-31.9-32.83l-16.12.08H118.26v56.4l28.55,21.38,27.05-9.77" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M274.37,183.26c2.8,1.18,11.28,3.89,16.71-1.83,4.45-4.69,5.43-11.5,3-16.55-3.58-7.4-10-11.42-18.31-10.46a17.91,17.91,0,0,0-13.86,10.22c-3.25,7-.62,14.52,1.46,19.71,12.07,30.13,27,47.33,29.19,52.55,3.65,8.76,5.67,18.43-3.33,26.46-4.95,4.42-17.52,6.08-25.13-3.1s-2-21.54,4.37-24.09c7.17-2.86-17.58-25.76-23.65-31.21v43.33H142l-.77,96.84,80.51-.77.17-35.13-46.58,25-21.79-27.8,32.31-49.6,21.79,7.52" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M274.37,183.26l.65.36c14.6,8,35,39.38,35,39.38l37.68-.19L347.8,138l-103,.83v44.86" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
            <path d="M894,524.69l.67,91.08c30.78-.38,56.89,24.83,57.27,55.61s-21.48,55-52.24,56.11c6.59,1.91,19.84,7.37,30.11,22.66,8.44-11.18,37.61-16.31,60.06-9.66,13.41,4,27.14,17.06,27.14,17.06,5.74-6.54,14-13.18,27.4-17.24" style={{fill: 'none', stroke: '#000', strokeMiterlimit: 10}} />
          </g>
          <g id="entradas_do_labirinto" data-name="entradas do labirinto">
          <Link to='/berço-venus/'>
            <polygon id="berço-de-venus" className="labirinto-link" points="471.87 894.51 471.87 908.4 499.63 908.4 499.63 933.58 472.51 933.9 472.53 947.38 594.84 947.38 594.9 933.9 567.73 934.18 567.73 908.4 595.5 908.4 595.5 894.51 471.87 894.51" style={{fill: '#ec98ac'}} />
            </Link>
            <Link to='/guia-interior/'>  
            <path id="guia-interior" className="labirinto-link" d="M41,851c40.73-1.18,72.27-29.88,71.7-75.09-.57,45.21,30.77,73.91,71.5,75.09v23.82l-142.9.35Z" style={{fill: '#ec98ac'}} />
            </Link>
            <Link to='/mente-certa/'>
            <path id="mente-certa" className="labirinto-link" d="M535.14,733.33c7.91,9,19.37,17.8,37.75,23.32a104.35,104.35,0,0,0,47.81,2.82c-7.07,21.85-22,61.35-88.53,61.35s-83.51-41.66-88.61-62.57a105.25,105.25,0,0,0,53.86-1.74c18.39-5.52,29.81-14.22,37.72-23.18Z" style={{fill: '#ec98ac'}} />
            </Link>
            <Link to='/mundo-sem-origem/'>
            <path id="mundo-sem-origem" className="labirinto-link" d="M501.4,208.56s24.76-8.8,27.19-16.94A86.39,86.39,0,0,0,531.65,176a.57.57,0,0,1,.55-.53.57.57,0,0,1,.56.54,79.87,79.87,0,0,0,3,15.6c2.45,8.13,27.19,16.94,27.19,16.94-6.72-2.68-17.66,7.71-21.59,25.05-3.23,14.23-8.7,29.17-9.21,44.54-.58-15.12-6-30.53-9.21-44.54-3.93-17.34-14.55-27.86-21.59-25.05" style={{fill: '#ec98ac'}} />
            </Link>
            <Link to='/sintese-radiante/'>
            <path id="sintese-radiante" className="labirinto-link" d="M344.75,279.76c-29.86,0-54.33,24.63-56.71,55.95h30.71c2.19-13.42,13-23.62,26-23.62,14.59,0,26.41,12.82,26.41,28.63s-11.82,28.64-26.41,28.64c-9.52,0-17.86-5.47-22.51-13.66H289.57c6.23,26.42,28.57,46,55.18,46,31.44,0,56.93-27.29,56.93-61S376.19,279.76,344.75,279.76Z" style={{fill: '#ec98ac'}} />
            </Link>
            <Link to='/toda-materia/'>
            <circle id="toda-materia" className="labirinto-link" cx="911.86" cy="896.97" r="25.42" style={{fill: '#ec98ac'}} />
            </Link>
            <Link to='/verbo-sideral/'>
            <circle id="verbo-sideral" className="labirinto-link" cx="666.83" cy="503.29" r="46.59" style={{fill: '#ec98ac'}} />
            </Link>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default labirintomobile
